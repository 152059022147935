import '/public/css/style.css'
import {useEffect} from "react";
import {state} from "state";
import Head from "next/head";
import {ContractApes, ContractMutants} from "helpers/Contract";
import {useSnapshot} from "valtio";
import TronLink from "helpers/TronLink";

export default function MyApp({ Component, pageProps }) {
	const snap = useSnapshot(state);

	useEffect( () => {
		async function InitContract() {
			return ({
				apes: await ContractApes.init(window.tronWeb),
				mutants: await ContractMutants.init(window.tronWeb)
			})
		}
		if (snap.login) InitContract().then(r => {
			if (r.apes && r.mutants) {
				state.tronWallet = true
				state.contract = true
			}
		})
	}, [snap.login])

	useEffect(() => {
		if (!process.env.TIMER && snap.timer) {
			const interval = setInterval(async () => {
				await TronLink();
			}, 2000);
			return () => clearInterval(interval);
		}
	});

  return (
		<>
			<Head>
				<link rel="apple-touch-icon" sizes="57x57" href="/pic/favicon/apple-icon-57x57.png"/>
				<link rel="apple-touch-icon" sizes="60x60" href="/pic/favicon/apple-icon-60x60.png"/>
				<link rel="apple-touch-icon" sizes="72x72" href="/pic/favicon/apple-icon-72x72.png"/>
				<link rel="apple-touch-icon" sizes="76x76" href="/pic/favicon/apple-icon-76x76.png"/>
				<link rel="apple-touch-icon" sizes="114x114" href="/pic/favicon/apple-icon-114x114.png"/>
				<link rel="apple-touch-icon" sizes="120x120" href="/pic/favicon/apple-icon-120x120.png"/>
				<link rel="apple-touch-icon" sizes="144x144" href="/pic/favicon/apple-icon-144x144.png"/>
				<link rel="apple-touch-icon" sizes="152x152" href="/pic/favicon/apple-icon-152x152.png"/>
				<link rel="apple-touch-icon" sizes="180x180" href="/pic/favicon/apple-icon-180x180.png"/>
				<link rel="icon" type="image/png" sizes="192x192"  href="/pic/favicon/android-icon-192x192.png"/>
				<link rel="icon" type="image/png" sizes="32x32" href="/pic/favicon/favicon-32x32.png"/>
				<link rel="icon" type="image/png" sizes="96x96" href="/pic/favicon/favicon-96x96.png"/>
				<link rel="icon" type="image/png" sizes="16x16" href="/pic/favicon/favicon-16x16.png"/>
				<link rel="manifest" href="/pic/favicon/manifest.json"/>
				<meta name="msapplication-TileColor" content="#ffffff"/>
				<meta name="msapplication-TileImage" content="/pic/favicon/ms-icon-144x144.png"/>
				<meta name="theme-color" content="#111113"/>
				{/*  */}
				<script dangerouslySetInnerHTML={{
					__html: `(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
   m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
   (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

   ym(84863713, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true
   });`}}/>
				{/* END  */}
			</Head>
			{/* (noscript) */}
			<noscript dangerouslySetInnerHTML={{
				__html: `<div><img src="https://mc.yandex.ru/watch/84863713" style="position:absolute; left:-9999px;" alt="" /></div>`}}/>
			{/* END  (noscript) */}
			<Component {...pageProps} />
		</>
  )
}
