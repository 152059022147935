import {proxy} from "valtio";

const state = proxy({
	tronWallet: false,
	loginMessage: true,
	login: false,
	contract: false,
	timer: true,
	mintValue: 1000,
	name: null,
	address: null,
	balance: null,
	network: null,
	link: false,
	eventServer: null,
	fullNode: null,
	solidityNode: null
});

export {state};
