const isProd =  process.env.NODE_ENV === "production";


const config = {
    
    prod: {
        mintingPrice: process.env.MINTING_PRICE,

        contractApes: 'TWi53fvZgTsW8tvAQmYVeThnBeyJqEfJhQ',
        contractApesBids: 'TF5rQYukq2ocPDTo4mwbSFN3afp6XBUxcT',

        contractMutants: 'TJjKSaj9UD9tQ27zvN6hpXiCwN2VsdNW7P',
        contractMutantsBids: '',

        contractQuest: 'TYk3pmmGarNtpkmrnyzGMZNnXaPF64NKpY',
        questPrice: 10e6,

        network: {
            fullNode: 'https://api.trongrid.io',
            solidityNode: 'https://api.trongrid.io',
            eventServer: 'https://api.trongrid.io'
        }
    }, 



    dev: {
        mintingPrice: process.env.MINTING_PRICE,

        contractApes: 'TAiLqLAEjVnWzrKaSTqM5V2K6vedCnyjZK',
        contractApesBids: 'TMqg5yT7GUuuSgMXprYi1TqA2AKrTbLJBB',

        contractMutants: 'TT2GfTX3s8CbsQteMkGoxxWBwMmcJL7x3U',
        contractMutantsBids: '',

        contractQuest: 'TRVvmJYd6DBPqHUHFJtcntsQFdrroVLpCW',
        questPrice: 1e6,

        network: {
            fullNode: 'https://api.shasta.trongrid.io',
            solidityNode: 'https://api.shasta.trongrid.io',
            eventServer: 'https://api.shasta.trongrid.io'
        }
    }
}



module.exports = isProd ? config.prod : config.dev;